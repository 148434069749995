<script>
import Layout from "../../layouts/horizontal";

import { mapGetters, mapActions } from 'vuex';
import VueRssFeed from "@/components/rssfeed";
import simplebar from "simplebar-vue";

export default {
  components: {
    Layout,
    VueRssFeed,
    simplebar
  },
  data() {
    
    return {
      expanded: {},
      feed:{
        feedUrl: null,
        name: "",
        limit: 10,
      },
      editingProfile: null,
      title: '',
      breadcrumbs: [
        {
          text: 'Account Summary',
          active: true
        }
      ]
    };
  },
  created() {
    if(process.env.VUE_APP_TYPE === 'CRYPTO') {
      this.feed.feedUrl = process.env.VUE_APP_BASE_URL + "/api/rss/cointelegraph" ;
    } else {
      this.feed.feedUrl = process.env.VUE_APP_BASE_URL + "/api/rss/yahoo";
    }
  
    this.init();
  },
  computed: {
     ...mapGetters('signalr', {
      signalrConnection: 'signalrConnection'
    }),
    ...mapGetters('users', {
      users: 'items',
      usersLoading: 'loading'
    }),
    ...mapGetters('portfolios', {
      usersPortfolioSummary: 'usersPortfolioSummary',
      portfoliosLoading: 'loading'
    }),
    isBusy (){
      return this.portfoliosLoading || this.usersLoading;
    },
    ...mapGetters('auth', {
      token: 'token'
    }),
  },
  watch: {
    signalrConnection: {
      handler: function (newValue) {
        console.log('signalrConnection changed');
        if(newValue) {
          this.init();
        }
      }
    }
  },
  methods: {
    ...mapActions('users', {
      loadUsers: 'load'
    }),
    ...mapActions('portfolios', {
      loadUsersPortfolioSummary: 'loadUsersPortfolioSummary'
    }),
    async init() {
      console.log('init admin index 1');
      if(this.signalrConnection) {
        console.log('init admin index 2');
        await this.loadUsers();
        await this.loadUsersPortfolioSummary({userIDs: this.users.map(x => x.id)});
      }
      
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
         <div class="email-leftbar p-0" >
            <div class="card p-0 cardc8 mt-2 rss-feed">
              <div class="card-content  p-0"> 
                  <div class="mt-3 ml-3 mr-3 card-header bg-transparent d-flex justify-content-between"><h5>News</h5></div>
                  <simplebar class="p-2" style="max-height: 600px;">
                    <VueRssFeed :feedUrl="feed.feedUrl" :name="feed.name" :limit="feed.limit"/>
                  </simplebar>
                
                </div>
            </div> 
          </div>
          <div class="email-rightbar mb-3 p-0">
            <div class="card p-0 cardc8 mt-2 rss-feed">
              <div class="card-content  p-0"> 
                <div class="mt-3 ml-3 mr-3 card-header bg-transparent d-flex justify-content-between"><h5>Account Summary<span v-if="isBusy" class="ml-2 fa fa-spin fa-spinner"></span></h5></div>
                <div  class="table-responsive" v-if="users.length > 0">
                  <table class="table table-borderless ">
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>E-mail</th>
                        <th>Daily Return</th>
                        <th>Monthly Return</th>
                        <th>Total Return</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="user in users" >
                        <tr :key="user.id">
                         
                          <td>
                            <a :href="'/users/' + user.id + '/portfolios'">{{user.name}}</a>
                          </td>
                          <td>
                            {{user.email}}
                          </td>
                          <td>
                            {{((usersPortfolioSummary[user.id] || {}).DailyReturn || 0).toFixed(2)}}
                          </td>
                          <td>
                            {{((usersPortfolioSummary[user.id] || {}).MonthlyReturn || 0).toFixed(2)}}
                          </td>
                          <td>
                            {{((usersPortfolioSummary[user.id] || {}).TotalReturn || 0).toFixed(2)}}
                          </td>
                         
                        </tr>
                      </template>
                    </tbody>
                  </table>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
 </Layout>
</template>